import Footer from 'features/Footer'
import Hero from 'features/Hero'
import Masthead from 'features/Masthead'
import PageLayout from 'layouts/PageLayout'
import { useRouter } from 'next/router'
import { PickFirst } from 'services/utils'
import Resources from 'features/Resources'
import Spacer from 'basics/Spacer'
import CtaBanner from 'features/CtaBanner'
import Container from 'containers/Container'
import GridContainer from 'containers/GridContainer'
import Select from 'basics/Select'
import TextInput from 'basics/TextInput'
import FeaturedLayout from 'layouts/FeaturedLayout'
import FeaturedResource from 'components/FeaturedResource'
import { nanoid } from 'nanoid'
import Heading from 'basics/Heading'
import SupportingContentBlocks from 'features/SupportingContentBlocks'
import { useEffect, useState } from 'react'

const LandingPagesContents = ({
  pageData,
  headline,
  subheadline,
  topNav,
  mainNav,
  gridTitle,
  meta,
  pathData,
  featured,
  width,
  hasFilters = true,
  isNews = true,
  supportingContent,
  resources = [],
  topics = [],
  categories = [],
  authors = [],
  solutions = [],
  footerCTA = [],
  footerContent = {},
  applicationSettings = {},
  noEventsMessage,
  showNoEventsMessage = false
}) => {
  const router = useRouter()
  const initialResources = resources
  initialResources.resource = router?.query?.type
    ? resources.resource.filter((resource) => {
        let id = resource?.type ? resource?.type[0]?.id : ''
        return id === router.query.type
      })
    : resources.resource
  const [filteredResources, setFilteredResources] = useState([])

  useEffect(() => setFilteredResources(initialResources), [])

  if (router.isFallback) {
    return null
  }
  const onChangeFilter = (ev) => {
    const target = ev.target
    //const query = {}
    //query[target.name || 'search'] = target.value ? encodeURI(target.value) : ''
    const append = target.value ? `/by/${target.name}/${target.value}` : ''
    location.href = `/resource-center${append}`
  }
  const numberFeatured = featured?.length || 0

  const onSearch = (ev) => {
    if (ev.target.value) {
      const filtered = { resource: [] }
      filtered.resource = initialResources.resource.filter((resource) => {
        return resource.title.toLowerCase().includes(ev.target.value.toLowerCase())
      })
      setFilteredResources(filtered)
    } else {
      setFilteredResources(resources)
    }
  }

  return (
    <PageLayout meta={meta} applicationSettings={applicationSettings}>
      <Masthead
        topLinks={topNav?.links || []}
        mainLinks={mainNav?.links || []}
        route={{ slug: ['resource-center'] }}
      />

      <Hero
        data={{
          headline,
          subheadline,
          width: width && width.length > 0 ? PickFirst(width).shortName : 'normal'
        }}
      />
      {showNoEventsMessage && (
        <Container>
          <div
            className="intro-paragraph text-align-center"
            dangerouslySetInnerHTML={{ __html: noEventsMessage }}
          />
        </Container>
      )}
      <Spacer size={10} mdSize={8} />

      {featured && featured.length > 0 && (
        <>
          <FeaturedLayout
            blocks={featured}
            onRender={({ block = {}, featured = false }) => {
              return (
                <FeaturedResource
                  key={nanoid()}
                  title={block?.title}
                  featuredImage={PickFirst(block?.featuredImage || []) || {}}
                  mainImage={PickFirst(block?.mainImage || []) || {}}
                  featured={featured}
                  url={PickFirst(block.pageBase)?.url}
                  len={numberFeatured}
                />
              )
            }}
          />

          <Spacer size={20} mdSize={13} />
        </>
      )}

      {supportingContent && <SupportingContentBlocks blocks={supportingContent} />}

      {hasFilters && (
        <Container narrow={true} className="mb-10">
          <GridContainer gridSize={3} gridGap={32}>
            <div>
              <label htmlFor="solution-select">Solutions</label>
              <Select
                id="solution-select"
                label="Select Solution"
                name="solution"
                onChange={onChangeFilter}
                value={
                  router.query?.slug?.length > 0 && router.query?.slug[0] === 'solution'
                    ? router.query?.slug[1]
                    : ''
                }
              >
                <option value="">All</option>
                {solutions.map((topic) => (
                  <option key={nanoid()} value={topic?.slug || ''}>
                    {topic.name}
                  </option>
                ))}
              </Select>
            </div>
            <div>
              <label htmlFor="category-select">Category</label>
              <Select
                id="category-select"
                label="Select Category"
                name="category"
                onChange={onChangeFilter}
                value={
                  router.query?.slug?.length > 0 && router.query?.slug[0] === 'category'
                    ? router.query?.slug[1]
                    : ''
                }
              >
                <option value="">All</option>
                {categories.map((topic) => (
                  <option key={nanoid()} value={topic?.slug || ''}>
                    {topic.name}
                  </option>
                ))}
              </Select>
            </div>
            <div>
              <label htmlFor="search-resources">Search</label>
              <TextInput
                placeholder="Search..."
                id="search-resources"
                type="search"
                onClickButton={onSearch}
                onKeyUp={(ev) => {
                  if (ev.key === 'Enter') {
                    onSearch(ev)
                  }
                }}
              />
            </div>
          </GridContainer>
        </Container>
      )}

      {gridTitle && filteredResources && (
        <Container className="mb-20">
          <Heading level={2} className="text-align-center h3">
            {gridTitle}
          </Heading>
        </Container>
      )}

      <Resources
        resources={filteredResources}
        paginate={true}
        isNews={isNews}
        key={
          router.query?.slug?.length > 0
            ? `${router.query?.slug[0]}:${router.query?.slug[1]}`
            : 'all'
        }
      />

      {filteredResources?.resource?.length === 0 && (
        <h3 className="text-align-center">There are no results.</h3>
      )}

      <Spacer size={20} mdSize={13} />

      <CtaBanner data={PickFirst(footerCTA)} />

      <Footer
        navLinks={topNav?.links || []}
        footerLinks={mainNav?.links || []}
        footerContent={footerContent}
      />
    </PageLayout>
  )
}

export default LandingPagesContents
