import PropTypes from 'prop-types'

import styles from './FeaturedResource.module.scss'
import Image from 'basics/Image'
import Heading from 'basics/Heading/Heading'
import Tag from 'basics/Tag'
import { ClassNames } from 'design-system/src/services/classname.service'
import { nanoid } from 'nanoid'
import Link from 'basics/Link'

const FeaturedResource = ({
  className,
  title,
  shortDescription,
  featuredImage,
  mainImage,
  startDate,
  featured = false,
  len,
  url
}) => {
  return (
    <section
      className={ClassNames([
        'flex-container',
        styles['ds-featured-resource'],
        featured ? styles['ds-featured-resource--large'] : null,
        len < 5 ? styles['ds-featured-resource--full'] : null,
        'sm-mb-4'
      ])}
    >
      {featuredImage && (
        <Link
          href={`/${url}`}
          chevron={false}
          className={styles['ds-featured-resource__background-link']}
          alt={`Link to ${title}`}
          >
          <Image src={featuredImage?.fileName} alt={featuredImage?.metadata?.alt} />
        </Link>
      )}
      {!featuredImage && mainImage && (
        <Link
          href={`/${url}`}
          chevron={true}
          className={styles['ds-featured-resource__background-link']}
        >
          <Image src={mainImage?.fileName} />
        </Link>
      )}
      <div key={nanoid()} className={styles['ds-featured-resource__content']}>
        <Heading level={2} className={featured ? 'mb-5' : 'mb-2'}>
          {url ? (
            <Link href={`/${url}`} chevron={true} className={styles['reverse-hover']}>
              {title}
              {startDate}
            </Link>
          ) : (
            <span>{title}</span>
          )}
        </Heading>
        {/* {featured && <Tag>Charity</Tag>} */}
      </div>
    </section>
  )
}

FeaturedResource.propTypes = {}

export default FeaturedResource
